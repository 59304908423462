<template>
  <app-page-section :title="{ tag: 'h3', text: 'Pollen, comment ça marche ?' }">
    <ul class="grid gap-10 gap-y-16 md:grid-cols-3">
      <li v-for="item in reassuranceItems" :key="item.cover">
        <figure>
          <app-image
            alt=""
            class="aspect-video rounded-lg object-cover md:aspect-auto"
            :src="item.cover"
          />
          <figcaption class="mt-8">
            <h3 class="mb-3 text-2xl font-extrabold">
              {{ item.title }}
            </h3>
            <p>{{ item.subtitle }}</p>
          </figcaption>
        </figure>
      </li>
    </ul>

    <template #append>
      <app-button size="xl" :to="{ name: 'catalog-index-top-trainers' }">
        Découvrir nos trainers
      </app-button>
    </template>
  </app-page-section>
</template>

<script lang="ts" setup>
import type { I18nMessage } from "@pollen/core/model/i18n.model";

const reassuranceItems: {
  title: I18nMessage;
  subtitle: I18nMessage;
  cover: string;
}[] = [
  {
    title: "Je sélectionne le thème et le trainer",
    subtitle:
      "Notre équipe peut vous aider à qualifier votre besoin et à trouver la meilleure personne pour vous accompagner.",
    cover: "/images/brand/session/interior_3.jpg",
  },
  {
    title: "Nous préparons la session ensemble",
    subtitle:
      "Vous rencontrez le trainer pour expliquer vos enjeux. Nous construisons la session adaptée avec le trainer et la méthodologie Pollen.",
    cover: "/images/brand/session/interior_4.jpg",
  },
  {
    title: "Vos talents se forment avec le trainer",
    subtitle:
      "Chaque participant recevra un support de formation et un certificat qui atteste sa formation.",
    cover: "/images/brand/session/interior_5.jpg",
  },
];
</script>
